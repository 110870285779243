<template>
    <div class="operating_terms_en_div">
        <div class="nav_div"></div>
        <div class="operating_terms_box">
            <p class="title_p">STANDARD TRADING CONDITIONS</p>
            <div class="time_div">
                <span class="time_span">Effective 23/10/2023</span>
            </div>
            <div class="paragraph_div">
             <p>1. In these Standard Trading Conditions (hereinafter referred to as “STC”), the following words have the following meanings:</p>
             <p>"Company" means Eastrong International Logistics Co., Ltd.上海東志國際貨物運輸代理有限公司.</p>
             <p>"Customer" means any person, body, company or corporation with whom the Company has a contract to provide the Services.</p>
             <p>"goods" includes goods, cargoes, wares, merchandise, and articles of every kind whatsoever (including articles of transport e.g. containers not supplied by or on behalf of the Company).</p>
             <p>"Dangerous Goods" means the goods that are of a dangerous, explosive, inflammable, radioactive and/or damaging nature. The goods of damaging nature include the goods likely to encourage pests. </p>
             <p>"Hague Rules" means the International Convention for the Unification of certain Rules of Law relating to Bills of Lading signed at Brussels on 25 August 1924.</p>
             <p>"Services" means all services of the Company provided for the Customer, e.g. storage, loading, unloading, packing, unpacking, labelling, distribution, stuffing, unstuffing, consolidation, transloading, collection, delivery, and/or other handling of goods; and/or carriage of goods by air, sea, inland waterway, land, rail and/or road.</p>
             <p>"SDR" means Special Drawing Rights as defined by the International Monetary Fund.</p>
             <p>2.1. All business undertaken by the Company is transacted subject to these STC, which are incorporated into any contract between the Company and the Customer.</p>
             <p>2.2. If at any time any term of these STC becomes invalid or illegal, the validity or legality of the remaining terms shall not in any way be affected.</p>
             <p>2.3. The Company may issue its own godown warrant, and/or a contract of carriage naming the Company as the carrier, e.g. waybill, bill of lading. Where such a document is issued, the terms in it shall prevail in so far as they are inconsistent with these STC.</p>
             <p>2.4. Where the Company is held to be a carrier, the Company shall be entitled to all the rights, immunities, exemptions, and limitations conferred on the carrier by any applicable law or legislation.</p>
             <p>2.5. Where the Company is held to be a carrier as far as carriage of goods by sea or inland waterway is concerned, the Company’s liability shall be determined by Article III and Article IV of the Hague Rules, and the aforesaid Articles shall prevail in so far as they are inconsistent with these STC. The amount of the limit of liability in Article IV (5) of the Hague Rules is deemed to be the nominal value of 100 pounds sterling, lawful money of the United Kingdom.</p>
             <p>2.6. In these STC, words importing the singular include the plural and vice versa; words importing a gender include every gender.</p>
             <p>2.7. Wherever it is provided in these STC that a notice is to be dispatched by the Company to the Customer, such a notice shall be deemed as having been dispatched if (i) the Company does not know the address, e-mail address or fax number of the Customer or (ii) the notice cannot reach the Customer through its address, e-mail address or fax number known to the Company.</p>
             <p>3. The Customer warrants that it fully complies with the applicable laws, rules and regulations of any ports, airports, Customs, and other authorities.</p>
             <p>4. The Customer further warrants that the goods are (i) properly and sufficiently packed, labelled and marked, and (ii) fit and suitable for carriage, storage and other handling. </p>
             <p>5.1. The Customer shall indemnify the Company against any claims, liability, losses, damage, costs and expenses (including loss of and damage to any aircraft, container and vessel) arising from or in connection with (i) the Company acting in accordance with the Customer's instructions, (ii) a breach of warranty or obligation on the part of the Customer, (iii) inaccurate or insufficient information provided by the Customer, and/or (iv) the mistake, negligence, wilful default or deliberate wrongdoing of the Customer, its employee, agent, contractor or sub-contractor.</p>
             <p>5.2. The Customer undertakes that no claims will be made against any employee, agent or sub-contractor of the Company if such claims are in connection with the Services. If any such claims are nevertheless made, the Customer shall indemnify the Company against all consequences. Every such employee, agent and sub-contractor has the benefit of all the terms of these STC benefiting the Company as if such terms were expressly provided for his or its benefit. For these purposes, the Company contracts for itself and also as agent and trustee for each employee, agent and sub-contractor.</p>
             <p>5.3. The Customer shall indemnify the Company against all claims and demands whatsoever and by whomsoever made in excess of the liability of the Company under these STC, and such indemnity shall include all claims and demands in connection with the negligence or mistake of the Company and in connection with the mistake, negligence, wilful default or deliberate wrongdoing of the Company’s employee, agent or sub-contractor.</p>
             <p>5.4. The Customer shall defend, indemnify and hold harmless the Company in respect of General Average claims and/or Salvage claims that may be made against the Company, and the Customer shall provide such General Average security and/or Salvage security as may be required by the Company. All goods are subject to lien for General Average security and/or Salvage security. If the Customer fails to provide General Average security or Salvage security acceptable to the Company within 14 days after the Company has dispatched a notice to the Customer asking the Customer to provide General Average security and/or Salvage security, the goods may be sold by public auction or private treaty or may be disposed of at the sole discretion of the Company at the Customer’s risk and expense, and the net proceeds (if any) shall be applied in satisfaction of General Average contribution and/or Salvage contribution. If the Customer does not receive the aforesaid notice dispatched by the Company, such shall not affect the Company’s aforesaid rights to sell or dispose of the goods. The Customer shall pay all costs and expenses (including storage costs and demurrage charges) being incurred when the goods are being liened for General Average security and/or Salvage security.</p>
             <p>6. The Customer warrants that the goods are not Dangerous Goods. Should the Customer nevertheless deliver any Dangerous Goods to the Company or cause the Company to handle any Dangerous Goods otherwise than under special arrangements previously made in writing, then whether or not the Company is aware of the nature of such Dangerous Goods, the Customer shall indemnify the Company against all penalties, claims, losses, damage, costs, expenses and liability in connection with such Dangerous Goods, and the Company shall be entitled to destroy or otherwise deal with such Dangerous Goods at the Customer’s risk and expense at the sole discretion of the Company. If any Dangerous Goods are handled by the Company under arrangements previously made in writing, the Company shall nevertheless be entitled to destroy such Dangerous Goods at the Customer’s risk and expense at the sole discretion of the Company on account of risk to other goods, property, life or health. </p>
             <p>7. Except under special arrangements previously made in writing, the Company will not deal with bullion, bank notes, coins, cheques, bonds, negotiable documents and securities, precious stones, precious metal objects, jewellery, antiques, valuable works of art, live animals or live plants. Should the Customer nevertheless deliver any such goods to the Company or cause the Company to handle any such goods otherwise than under special arrangements previously made in writing, the Company shall accept no liability in connection with such goods.</p>
             <p>8.1. If the Customer does not take delivery of the goods at the time and place when and where the delivery should be taken, the Company shall be entitled to store the goods at the risk of the Customer, whereupon any liability which the Company may have in respect of the goods stored as aforesaid shall wholly cease and the costs and expenses of such storage (including demurrage charges) shall be paid by the Customer to the Company.</p>
             <p>8.2. The Company shall be entitled to sell the goods by public auction or private treaty or to dispose of the goods at the Company’s sole discretion and at the Customer’s risk and expense if the Customer does not take delivery of the goods within 14 days after the Company has dispatched a notice to the Customer asking the Customer to take delivery of the goods.  If the Customer does not receive the aforesaid notice dispatched by the Company, such shall not affect the Company’s aforesaid rights to sell or dispose of the uncollected goods. The Customer shall pay all costs and expenses (including demurrage charges) in connection with the storage and the sale or disposal of the goods.</p>
             <p>8.3. All goods and documents relating to such goods are subject to (i) particular lien for monies due in respect of such goods, and (ii) general lien for any other monies due from the Customer to the Company. If any such monies due to the Company are not paid within 14 days after the Company has dispatched a notice to the Customer asking the Customer to pay the outstanding monies, the goods may be sold by public auction or private treaty or may be disposed of at the sole discretion of the Company at the Customer’s risk and expense, and the net proceeds (if any) shall be applied in satisfaction of such debts, and the Customer shall not be relieved from the liability for the outstanding debts merely because the goods have been sold or disposed of. If the Customer does not receive the aforesaid notice dispatched by the Company, such shall not affect the Company’s aforesaid rights to sell or dispose of the goods.  The Customer shall pay all costs and expenses (including storage costs and demurrage charges) being incurred when the goods are being liened.</p>
             <p>9.1. The Customer shall pay the Company all sums immediately when they are due without deduction on account of any claim, counterclaim or set-off. Except under special arrangement agreed by the Company in writing, payment to the Company is due as soon as an invoice is rendered to the Customer. For any amount unpaid within 30 days from the due date, the Company shall be entitled to interest at 2% per month from the date of the invoice until payment.</p>
             <p>9.2. If the shipment is on the freight collect basis but the consignee does not take delivery of the goods within 14 days from the date of the goods’ arrival at the place of delivery, the Customer shall be responsible for payment of all the outstanding freight charges, and the costs and expenses (including storage costs and demurrage charges) incurred until the goods are taken delivery or are sold or disposed of in accordance with Clauses 8.2 and/or 8.3.</p>
             <p>9.3. Any Services provided gratuitously are on the basis that the Company will not accept any liability whatsoever or howsoever caused.</p>
             <p>10.1. The Company is entitled to sub-contract the whole or any part of the Services to any agents or sub-contractors on any terms.</p>
             <p>10.2. The Company reserves to itself the absolute discretion and is at liberty to choose the means, manner, routes and procedures in the performance of the Services, including using any vessel, stowing the goods on or under deck, doing transshipment. Anything done in accordance with the aforesaid discretion and/or liberty shall not be deemed as a deviation of whatsoever nature or degree.</p>
             <p>11. The Company is not liable for any claim relating to any incident not caused by the negligence or mistake of the Company, its employee, agent or sub-contractor.</p>
             <p>12. If there is any loss, damage, misdelivery (other than the ones stated in Clauses 13.1 and 13.2), non-delivery, or misdirection of or to or in connection with goods that arises from the negligence or mistake of the Company or from the negligence, mistake, wilful default or deliberate wrongdoing of the Company’s employee, agent or sub-contractor, the Company shall be liable for the claim relating to the aforesaid incident. However, the Company’s aforesaid liability shall not exceed a sum of 2 SDR per kilogram of the gross weight of the goods in respect of which the claim arises.</p>
             <p>13.1. If there is any misdelivery of goods without the consignee’s production of the Bill of Lading negligently or deliberately done by the Company’s employee, agent or sub-contractor without the Company’s approval, the Company shall be liable for the claim relating to the aforesaid misdelivery of goods. However, the Company’s aforesaid liability shall not exceed a sum of 2 SDR per kilogram of the gross weight of the goods misdelivered.</p>
             <p>13.2. If the Company’s employee, agent or sub-contractor negligently or deliberately misdelivers the goods to a person (not entitled to receive the goods) without the Company’s approval, the Company shall be liable for the claim relating to the aforesaid misdelivery of goods. However, the Company’s aforesaid liability shall not exceed a sum of 2 SDR per kilogram of the gross weight of the goods misdelivered.</p>
             <p>14. Notwithstanding any other terms to the contrary in these STC but subject to Clauses 2.3 and 2.5, the Company is not liable for any claim relating to:</p>
             <p>a. delay, goods having been shut out or off loaded, goods’ departure or arrival time,</p>
             <p>b. incidental, indirect or consequential losses (including loss of market, loss of profit, loss of tax, loss of tax refund, loss of revenue, loss of income, loss of business, and loss of goodwill), or</p>
             <p>c. loss of or damage to goods, costs, expenses arising from or in connection with fire, flood, storm, typhoon, explosion, war, sanctions, port congestion, airport congestion, deviation, strike, lockout, stoppage or restraint of labour,</p>
             <p>regardless of whether or not the aforesaid incident arises from the negligence or mistake of the Company or from the negligence, mistake, wilful default or deliberate wrongdoing of the Company’s employee, agent or sub-contractor. However, if the Company is still legally found liable for the aforesaid claim despite the aforesaid provision, the Company’s liability shall not exceed a sum of 2 SDR per kilogram of the gross weight of the goods in respect of which the claim arises.</p>
             <p>15. If there is any claim (other than the ones stated in Clauses 12, 13.1, 13.2 and 14) that the Company is legally found liable, the Company’s liability shall not exceed a sum of 75,000 SDR per event or events arising from a common cause.</p>
             <p>16. The liability of the Company under Clauses 12, 13.1, 13.2 and 14 shall be subject to a further limit of 200,000 SDR per event, i.e., the Company’s liability under Clauses 12, 13.1, 13.2 and 14 shall not in any circumstances whatsoever exceed a sum of 200,000 SDR per event or events arising from a common cause. </p>
             <p>17. The Company can accept liability in excess of the limits stated in Clauses 2.5, 12, 13.1, 13.2, 14, 15 and 16 provided that (i) the value of the goods is declared or a higher limit is requested in writing by the Customer, and such value or limit is accepted by the Company in writing as the new limit of liability and (ii) the Customer pays the Company the additional charges as decided by the Company. Details of the additional charges may be provided upon written request by the Customer. </p>
             <p>18. The Company is not liable for any claim other than the ones for which the Company has specified its accepting liability under Clauses 2.5, 12, 13.1, 13.2, 14, 15, 16 and 17.</p>
             <p>19. Any superficial rust, oxidation, discoloration, or any like condition due to moisture is not condition of damage but is inherent to the nature of the goods, and acknowledgement of receipt of the goods in apparent good order and condition by the Company is not a representation that such rust, oxidation, discoloration, or the like condition due to moisture did not exist on receipt.</p>
             <p>20. If the Services are or are likely to be affected by any risk, delay, hindrance, difficulty and/or disadvantage of any kind whatsoever, whensoever and howsoever arising (including war, sanctions, port congestion, airport congestion, strike, lockout, stoppage, restraint of labour, and the Customer’s failure to pay the Services charges that have been outstanding for 2 months or more), the Company may terminate and/or abandon the Services and place the goods at any place for the Customer to take delivery of the goods, whereupon the Company’s liability and responsibility in respect of the goods shall cease. The Customer shall pay all the outstanding Services charges, and all costs and expenses (including storage costs and demurrage charges) incurred until the goods are taken delivery by the Customer. If the Customer does not take delivery of the goods or does not pay the outstanding Services charges, costs or expenses within 14 days after a notice has been dispatched to the Customer, the Company shall be entitled to sell the goods by public auction or private treaty or to dispose of the goods at the Company’s sole discretion and at the Customer’s risk and expense, and the net proceeds (if any) shall be applied in satisfaction of the outstanding Services charges, costs and expenses. The Customer shall not be relieved from the liability for the outstanding Services charges, costs or expenses merely because the goods have been sold or disposed of. If the Customer does not receive the aforesaid notice dispatched by the Company asking the Customer to take delivery of the goods and to pay the outstanding Services Charges, costs and expenses, such shall not affect the Company’s aforesaid rights to sell or dispose of the goods.</p>
             <p>21. Notice of any claim must be in writing and delivered to the Company by the Customer within 14 days from the date of delivery of the goods to the Customer by the Company or the date the goods should have been delivered or the date the Customer first knows about the event giving rise to the claim, whichever is the earliest. If the Customer fails to deliver the aforesaid written claim notice to the Company within 14 days and thereby prejudices the Company’s position or interests in the claim case, the Company shall in any event be discharged from all liability in respect of the claim.</p>
             <p>22. The Company shall in any event be discharged from all liability whatsoever and howsoever caused in respect of the Services unless suit is brought against the Company within 9 months from the date of delivery of the goods to the Customer by the Company or the date the goods should have been delivered or the date of the event giving rise to the claim, whichever is the earliest.</p>
             <p>23. The defences, exemptions and limits of liability provided for in these STC shall apply in any action against the Company whether such action is founded in contract or in tort.</p>
             <p>24. All contracts with the Company and these STC are governed by the law of the People’s Republic of China. Any claim or dispute must be determined exclusively by the courts in the People’s Republic of China and no other courts.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {},
    watch: {},
    methods: {},
    data() {
        return {

        }
    }
}
</script>
<style lang="less">
.operating_terms_en_div{
    .operating_terms_box{
        line-height: 48px;
        margin: 30px 0;
        .title_p{
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }
        .time_div{
            text-align: center;
            .time_span{
                font-size: 16px;
                // margin: 0 100px 0 0;
            }
        }
        .paragraph_div{
            width: 80%;
            margin: 0 auto;
            p{
                text-indent:24px
            }
        }
    }
}
</style>
